.nv__preload{
    width: 100%;
    height: 100vh;
    position: relative;

    .nv__slider{
    
        &__item{
            height: 100vh;
        }
    }


    .swiper-pagination{
        display: none !important;
    }

    &__footer__menu{
        width: 1170px;
        height: 20px;
        position: absolute;
        bottom: 60px;
        z-index: 999;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        padding: 20px 0px 20px 0px;
        border-radius: 10px;
    }

    &__menu{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-color;
        


        li{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            list-style: none;
            font-weight: 600;
            margin-right: 50px;
            :last-child{
                margin-right: 0;
            }
            position: relative;
            padding-top: 20px;
        }
        a{
            width: auto;       
            height: auto;
            padding: 14px;
            //border: 1px solid $white-color;
            border-radius: 25px;
            color: $white-color;
            font-size: 16px !important;
                &:hover{
                    color: $white-color;
                }
        }

        .menu__item{
            &:hover{
                .submenu{
                    visibility: visible;
                }
            }
        }
        .submenu{
                width: 200px;
                height: auto;
                background-color:rgba($color: #000000, $alpha: 0.6);
                position: absolute;
                bottom: 50px;
                left: 0px;
                padding-top: 20px;
                visibility: hidden;
                li{
                    width: 80%;
                    height: auto;
                    display: block;
                    margin: 0 auto;
                    padding-top: 0px;
                    margin-bottom: 30px;
                    font-size: 16px !important;

                    a{
                        background-color: transparent;
                        &:hover{
                            color: $blue-color;
                        }
                        
                    }
                }
        }
    }

}

.nv__aliances{
    height: auto;
    position: fixed;
    top: 40%;
    left: 0px;
    bottom: auto;
    z-index: 999;
    -webkit-box-shadow: 6px 6px 7px -5px rgb(0 0 0 / 75%);
    -moz-box-shadow: 6px 6px 7px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 7px -5px rgb(0 0 0 / 75%);
    background: rgb(0 0 0 / 75%);
    padding-top: 8px;
    color: $white-color;
    display: inline-block;
    writing-mode: vertical-lr;
    text-orientation: upright;

    &__item{
        width: 80%;
        height:auto;
        position: relative;
        margin: 0 auto;
        margin-bottom: 15px;
        cursor: pointer;
        &:first-child{
            margin-top: 10px;
        }
        &:last-child{
            margin-bottom:10px;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__preload{
        &__footer__menu{
            display: none;
        }
    }
}