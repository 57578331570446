.nv__services{
    width: 100%;
    min-height: 90vh;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;

    &--mt{
        padding-top: 120px;
    }

    
    &__container{
        width: 100%;
        height: auto;
        display: flex;
    }

    .subtitle{
        text-transform: uppercase;
    }

    &__seemore{
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;

        svg{
            font-size: 12px;
            margin-left:5px;
        }
    }

    &__two__block{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__half{
        width:50%;
        height: auto;
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;

        .nv__services__block__item{
            width: 100%;
            height: 260px;
            background-size: cover !important;
            background-position: left center !important;
                
            
            &--1{
                background: url("../../../assets/images/home/services/services-1.png");
            }

            &--2{
                background: url("../../../assets/images/home/services/services-2.png");
            }

            &--3{
                background: url("../../../assets/images/home/services/services-3.png");
            }

            &--4{
                background: url("../../../assets/images/home/services/services-4.png");
            }
        }

        &__logo{
            width: 140px;
            height: auto;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }

        p{
            width: 100%;
        }

        &:last-child{
            margin-right: 0px;
        }
      
    }

    &__info{
        width: 80%;
        height: auto;
        padding:40px 0px 0px 40px;
        color: $white-color;
        span{
            font-size: 11px;
            line-height: 20px;
        }
        h3{
            font-size:  30px;
            line-height: 36px;
            margin-bottom: 20px;
        }

        p{
            text-align: justify;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        a{
            text-transform: none !important;
            font-size: 14px;
            color: $white-color;
        }
    }

    &__block{
        width: 50%;
        height: 100%;
        margin-bottom:10px;

        &__item{
            width: 98%;
            height: 800px;
            background: url("../../../assets/images/home/services/services-1.png");
            background-size: cover;
            background-position: left center;
            &--50-1{
                width: 100%;
                height: 300px;
                background: url("../../../assets/images/home/services/services-1.png") ;
                background-size: cover;
                margin-bottom: 10px;
            }
            &--50-2{
                width: 100%;
                height: 300px;
                background: url("../../../assets/images/home/services/services-1.png");              
                background-position: top;
                background-size: cover;
                margin-bottom: 10px;
            }
 
        }
    }


}



@media screen and (max-width: 1170px){
    .nv__services{
        width: 100%;
        
        .nv__section__container{
            width: 95%;
        }
    }
}


@media screen and (max-width: 992px){
    .nv__services{
        margin-bottom: 40px;
        &__info{
            h3{
                font-size:  26px;
                line-height: 32px;
            }
    
            p{
                text-align: justify;
                font-size: 14px;
                line-height: 18px;
            }
            
        }
    }
}


@media screen and (max-width: 768px){
    .nv__services{
    
        &__block{
            margin: 0px;
            &__item{
                width: 98%;
                height: 410px;
                &--50-1{
                    width: 100%;
                    height: 240px;
                }
                &--50-2{
                    width: 100%;
                    height: 240px;
                }
            }
        }

        &__two__block{
            flex-direction: column;
        }
        &__half{
            width:100%;
            height: auto;
            position: relative;
            margin-right: 0px;

            .nv__services__block__item{
                width: 100%;
                height: 240px;
            }

            &__logo{
                display: none;
            }
            p{
                width: 100%;
            }
        }
        
    }

}

@media screen and (max-width: 680px){
    .nv__services{
        .nv__section__container{
            width: 100%;
        }
        &__info{
            width: 90%;
            padding: 20px 0px 0px 20px;
            h3{
                font-size:  20px;
                line-height: 26px;
            }
    
            p{
                text-align: justify;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &__container{
            flex-wrap: wrap;
        }
        &__block{
            width: 100%;
            
            &__item{
                width: 100%;
                height: 200px;
                margin-bottom: 0px;
                &--50-1{
                    width: 100%;
                    height: 200px;
                }
                &--50-2{
                    width: 100%;
                    height: 200px;
                }
            }
        }

        
    }
}