.nv__footer{
    width: 100%;
    height: auto;
    background-color:$strong-gray-color;
    color: $white-color;
    padding: 20px 0px 20px 0px;

    .nv__section__container{
        display: flex;
        justify-content: space-between;
    }

    p{
        width: 100%;
        height: auto;
        font-size: 16px;
        line-height: 22px;
    }

    &__logo{
        width: 100px;
        height: auto; 
        margin: 0 auto;

        &--mobile{
            display: none;  
        }
    }

    &__block{
        &__item{
            width: 50%;
            height: auto;
            border-right: 2px solid $white-color;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:nth-child(2) {
                text-align: center;
                box-sizing: border-box;

                p{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }
            }

            &:last-child {
                border-right: none;
                margin-right: 0;
            }
        }

        
        &--big{
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-content: center;
        }

    }
}

@media screen and (max-width: 1170px){
    .nv__footer{
        width: 100%;
        
        .nv__section__container{
            width: 85%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__footer{   
        &__logo{
            width: 100px;
        }
        .nv__section__container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__block{
            &__item{
                &:last-child {
                    border-right: 0px ;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .nv__footer{   
        &__logo{
            width: 100px;
            &--mobile{
                display: block;
            }
        }

        p{
            font-size: 16px;
            line-height: 26px;
        }

        .nv__section__container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__block{
            margin-bottom: 20px;
            &__item{
                margin-right: 0px;
                &--web{
                    display: none;
                }

                &:nth-child(2) {
                    border-right: 0px ;
                }
            }
        }
    }
}


@media screen and (max-width: 480px){

    .nv__footer{   
    
        &__block{
            flex-wrap: wrap;
            &__item{
                width: 100%;
                height: auto;
                border-right: none;
                margin-right: 0px;
                margin-bottom: 20px;

                &:nth-last-child(2) {
                    margin-bottom: 0px;
                }
            }   
        }
    }
}