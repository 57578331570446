.nv__back_to_nv{
    width: 200px;
    height: auto;   
    position: fixed;
    bottom: 30px;
    left: 30px; 
    z-index: 99;
    left: 0px;
    right: 0px;
    margin: 0 auto;

    .nv__back_to_nv__logo{
        width: 180px;
        margin: 0 auto;
        filter: drop-shadow(2px 2px 2px  rgba(0, 0, 0, 1));
        visibility: hidden;
    }
    &__text{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: $white-color;
        text-align: center;
        margin: 0 auto;
        svg{
            font-size: 24px;
        }
    }
    
    
}

.nv__back_to_nv.fixed-container {
    width: 100%;
    height: auto;
    position: relative;
    background-color: $strong-blue-color;
    bottom: 0px;
    -webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
    
    .nv__back_to_nv__logo{
        width: 200px;
        margin: 0 auto;
        padding: 10px;
        box-sizing: border-box;
        visibility: visible;
    }

}

.no-scroll{
    visibility: visible !important;
}