.ant-menu-dark{
    background-color: transparent !important; // background-color: $strong-blue-color !important;
    color: $white-color !important; 
}

.ant-menu-dark .ant-menu-item-selected{
    background-color: $strong-blue-color !important;
}

.ant-menu-dark li{
    font-size: 16px;
}